<template>
    <div v-if="showPage" class="self-extract-container" style="background-color: #ffffff">
        <div class="header">
            <div>
                   <search-v2
                            class="doneOrderList_searchBox_item header-search"
                            v-model="keyword"
                            @search="search"
                            @clear="search"
                            placeholder="请输入商品名称"

                    ></search-v2>
                <div class="date-ranger-container" style="padding-top: 10px;">
                    <date-ranger-v1 :orginDate="date" @chooseDate="chooseDate"></date-ranger-v1>
                </div>
            </div>

            <div class="header-menu">
                <div class="header-menu-left" @click="goToDetails">
                    <div> <img style="height:33px;vertical-align:middle" :src="require('@/static/image/icons/tj-a.png')"></div>
                    <div style="margin-left: 9px;">数据统计</div>
                </div>
                <div class="header-menu-right" @click="goToMoney">
                    <div>  <img style="height:33px;vertical-align:middle" :src="require('@/static/image/icons/tj-b.png')"></div>
                    <div style="margin-left: 9px;">资金统计</div>
                </div>

            </div>
        </div>


        <!--供货商统计-->
        <div v-if="authList.indexOf(1)>-1">
            <div class="div-middle"></div>
            <div class="tongji-body" style="margin-bottom:8px;">

                <!--商品名称-->
                <div class="tongji-head">
                    <div style="display:flex;justify-content: left;align-items: center;">
                        <div class="tongji-head-tip"></div>
                        <div>供货商统计</div>
                    </div>

                        <search-v2
                                class="doneOrderList_searchBox_item header-search"
                                v-model="keywordTwo"
                                @search="searchTwo"
                                @clear="search"
                                placeholder="请输入供货商名称"
                        ></search-v2>

                </div>

                <div class="tongji-result">
                    <div class="tongji-result-title">{{date}}下单未核销商品</div>
                </div>
                <!--统计结果-->
                <div class="tongji-result" v-for="(item, index) in storeList" :key="index">
                    <div class="tongji-result-title">{{item.store_name}}<span style="color:#F46A17;">(未核销商品)</span></div>
                    <div class="tongji-result-line"></div>
                    <div class="tongji-result-desc" @click="showStoresList(index,'stores')">
                        商品总数：{{item.total}}件
                        <img v-if="show && index==storeIndex && showTip=='stores'"  class="tongji-result-desc-tip" :src="require('@/static/image/icons/r-down.png')">
                        <img v-else class="tongji-result-desc-tip" :src="require('@/static/image/icons/r-up.png')">
                    </div>


                    <div  v-show="show && index==storeIndex && showTip=='stores'" class="tongji-result-details"  v-for="(dItem,dIndex) in item.list" :key="dIndex">
                        <div style="width:260px;">
                            {{dIndex+1}}.{{dItem.title}}
                        </div>
                        <div class="tongji-result-details-tip">{{dItem.total}}件</div>
                    </div>

                </div>

            </div>
        </div>



        <!--自提点配送统计-->
        <div v-if="authList.indexOf(1)>-1">
            <div class="div-middle"></div>
            <div class="tongji-body">
                <div class="tongji-head">
                    <div style="display:flex;justify-content: left;align-items: center;">
                        <div class="tongji-head-tip"></div>
                        <div>自提点配送统计</div>
                    </div>

                    <div>
                        <search-v2
                                class="doneOrderList_searchBox_item header-search"
                                v-model="keywordThree"
                                @search="searchThree"
                                @clear="search"
                                placeholder="请输入自提点名称"

                        ></search-v2>
                    </div>
                </div>

                <div class="tongji-result">
                    <div class="tongji-result-title">{{date}}下单未核销商品</div>
                </div>

                <!--统计结果-->
                <div class="tongji-result" style="background: rgba(23, 127, 244, 0.1);"  v-for="(item, index) in pointList" :key="index">
                    <div class="tongji-result-title">{{item.site_name}}<span style="color:rgb(23, 127, 244);">(未核销商品)</span></div>
                    <div class="tongji-result-line" style="background: rgba(23, 127, 244, 0.1);"></div>
                    <div class="tongji-result-desc" style="color: #177FF4;" @click="showStoresList(index,'sites')">
                        商品总数：{{item.total}}件
                        <img  v-if="show && index==storeIndex && showTip=='sites'" class="tongji-result-desc-tip" :src="require('@/static/image/icons/g-down.png')">
                        <img v-else class="tongji-result-desc-tip" :src="require('@/static/image/icons/g-up.png')">
                    </div>


                    <div  v-show="show && index==storeIndex && showTip=='sites'" class="tongji-result-details"  v-for="(dItem,dIndex) in item.list" :key="dIndex">
                        <div style="width:260px;">
                            {{dIndex+1}}.{{dItem.title}}
                        </div>
                        <div class="tongji-result-details-tip">{{dItem.total}}件</div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
    import SearchV2 from "../../template/community/search/searchV2";
    import DateRangerV1 from "../../template/community/date/dateRangerV1";

    export default {
        name: 'statistics',
        components: {SearchV2,DateRangerV1},

        data(){
          return{
            showPage:false,
              date:[], //搜索时间
              authList:[], //权限列表
              storeList:[], //商品列表
              pointList:[], //自提点列表
              show:false,//操作的板块
              showTip:'', //操作的板块
              storeIndex:-1,
              keyword:'', //商品
              keywordTwo:'',// 供货商
              keywordThree:'',// 自提点名称
          }
        },

        created () {
            this.getYearDate()
            //店铺备货列表
            this.getStoresStatistics({ date:this.date})
            //自提点备货列表
            this.getPointStockUpNums({ date:this.date})
            //获取权限列表
            this.myCenterInfo()

        },

        methods: {
            //时间搜索
            chooseDate(date) {
                this.date=date.toString();
                this.search()
            },
            getYearDate:function(){

                var today = new Date();
                var year = today.getFullYear();
                var month = today.getMonth()+1;
                var day = today.getDate();

                var laskTime=today.getTime()-1*24*60*60*1000;
                var tragetTime=new Date(laskTime);
                var tragetYear = tragetTime.getFullYear();
                var tragetMonth = tragetTime.getMonth()+1;
                var tragetDay = tragetTime.getDate();
                this.date=tragetYear+'-'+tragetMonth+'-'+tragetDay+','+year+'-'+month+'-'+day

            },
            goToDetails () {
                console.log(this.authList)
                if (this.authList.indexOf(3)==-1){
                    //console.log(this.authList.indexOf(1))
                    this.$toast.text('权限不足');
                }else{
                    if (this.$platform.wxsdk.isWechat()) {
                        this.$platform.wxsdk.wxRoute({
                            type: 'navigateTo',
                            url: '/web/selfExtract/statistics-details',
                        })
                    } else {
                        this.$router.push({
                            path: 'statistics-details',
                        })
                    }
                }

            },
            goToMoney () {
                console.log(this.authList)
                if (this.authList.indexOf(4)==-1){
                    //console.log(this.authList.indexOf(1))
                    this.$toast.text('权限不足');
                }else {
                    if (this.$platform.wxsdk.isWechat()) {
                        this.$platform.wxsdk.wxRoute({
                            type: 'navigateTo',
                            url: '/web/selfExtract/money-statistics',
                        })
                    } else {
                        this.$router.push({
                            path: 'money-statistics',
                        })
                    }
                }
            },
            search () {
                let params={
                    keyword:this.keyword,
                    store_name:this.keywordTwo,
                    point_name:this.keywordThree,
                    date:this.date
                }

                this.getStoresStatistics(params)
                this.getPointStockUpNums(params)
            },
            searchTwo () {
                let params={
                    keyword:this.keyword,
                    store_name:this.keywordTwo,
                    date:this.date
                }
                this.getStoresStatistics(params)

            },
            searchThree () {
                let params={
                    keyword:this.keywordTwo,
                    point_name:this.keywordThree,
                    date:this.date
                }
                this.getPointStockUpNums(params)

            },

            //按店铺商品和商品列表分类备货
            async getStoresStatistics(params) {
                console.log(params)
                try {
                    let query = await this.$api.community.points.getStoresStatistics(params)

                    this.storeList = query.data
                    this.storeAuth=true
                } catch (error) {
                    this.storeList =[];
                    this.storeAuth=false
                }

            },
            //按店铺商品和商品列表分类备货
            async getPointStockUpNums(params) {
                try {
                    let query = await this.$api.community.points.getPointStockUpNums(params)

                    this.pointList = query.data
                    this.pointAuth=true
                } catch (error) {
                    this.storeList =[];
                    this.pointAuth=false
                }

            },

            async myCenterInfo () {
                try {
                    let query = await this.$api.community.points.getStatisticsAuth()
                    let getAuthList=query.data
                    if (getAuthList.length>0){
                        getAuthList.forEach((item, index)=>{
                            this.authList.push(item.auth_id)
                        })
                    }
                    console.log('getStatisticsAuth success', this.authList)
                } catch (error) {
                    console.log('dataInfo error', error)
                }
            },
            showStoresList(index,showTip){

                if (index==this.storeIndex && this.showTip==showTip){
                    this.show=!this.show
                }else{
                    this.storeIndex=index
                    this.show=true
                }
                this.showTip=showTip

            }
        }
    }
</script>

<style scoped lang="scss">
    div{
        border:0px solid red;
    }
    .self-extract-container {
        background: #fff;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        padding-top: 10px;

        .header{
            margin:auto;
            width: 345px;
            padding-bottom: 15px;
            .header-search{

                height: 36px;
                background: #F6F6F6;
                border-radius: 18px;
            }
            .header-menu{
                height:80px;
                margin-top:20px;
                display:flex;
                justify-content: space-between;
                line-height:80px;
                font-size: 18px;
                font-family: PingFang-SC-Heavy, PingFang-SC;
                font-weight: 800;
                color: #FFFFFF;
                align-items: center;

                .header-menu-left{
                    height: 80px;
                    width:163px;
                    background: linear-gradient(131deg, #8CBFFF 0%, #4199FF 100%);
                    border-radius: 4px;
                    display: flex;
                    justify-content:center;
                }
                .header-menu-right{
                    height: 80px;
                    width:163px;
                    background: linear-gradient(139deg, #FAC484 0%, #F46A17 100%);
                    border-radius: 4px;
                    display: flex;
                    justify-content:center;

                }
            }
        }

        .div-middle{
            height: 8px;
            width:375px;
            background: #F8F8F8;
        }

        .tongji-body{
            margin:auto;
            width: 345px;
            .tongji-head{
                height:57px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #303030;
                line-height: 57px;
                display: flex;
                align-items: center;
                justify-content:space-between;

                .tongji-head-tip{
                    width: 4px;
                    height: 16px;
                    background: linear-gradient(180deg, #FBA332 0%, #F46A17 100%);
                    border-radius: 4px;
                    margin:4px
                }

            }


            .tongji-result{
                width: 345px;
                background: rgba(244, 106, 23, 0.1);
                border-radius: 6px;
                margin-bottom: 9px;

                .tongji-result-title{
                    height: 39px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #303030;
                    line-height: 39px;
                    padding-left: 13px;
                }
                .tongji-result-line{
                    width: 315px;
                    height: 1px;
                    background: #FFDFCB;
                    margin: auto;
                }
                .tongji-result-desc{
                    padding-left: 13px;
                    height: 37px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #F46A17;
                    line-height: 37px;
                    display:flex;
                    justify-content:space-between;
                    align-items: center;

                    .tongji-result-desc-tip{

                        width:10px;
                        line-height:37px;

                        margin-right: 10px;
                    }
                }



                .tongji-result-details{
                    padding-left: 13px;
                    height: 37px;
                    font-size: 12px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 15px;
                    display:flex;
                    justify-content:space-between;
                    align-items: center;

                    .tongji-result-details-tip{
                        width:50px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        margin-right: 10px;
                        text-align: right;


                    }
                }

            }


        }

    }
</style>
